import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import ReportOverviewPartnerPayout from './report-overview-partner-payout';

const ReportOverviewPartner = inject("stores") (
    observer (
        class ReportOverviewPartner extends Component {

            constructor(props) {
                super(props);
                this.props = props; 
                this.storeUi = this.props.stores.storeUi;
                this.storeReports = this.props.stores.storeReports;
                this.storePartners = this.props.stores.storePartners;
                
                this.state = {
                    partner: []
                }
            }

            componentDidMount = async() => {
                this.setState({
                    partner: this.props.partner
                })
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.id !== this.props.id) {
                    const payouts = await this.storePartners.getPartnerPayouts(this.props.id);
                    const sales = await this.storePartners.getPartnerAllSales(this.props.id);

                    this.setState({
                        partner: this.props.partner,
                        payouts: payouts,
                        sales: sales
                    })
                } 
            }

            calcTotalPayouts = () => {
                let total = 0;
                total = this.storePartners.calcTotalPayouts(this.state.payouts);

                return total;
            }

            calcTotalSales = () => {
                let total = 0;
                total = this.storePartners.calcTotalSales(this.state.partner, this.state.sales);
                
                return total;
            }

            calcTotalCommissions = () => {
                let total = 0;
                total = this.storePartners.calcTotalCommissions(this.state.partner, this.state.sales);
                
                return total;
            }

            calcTotalCommissionsOpen = () => {
                let total = 0;
                let commissions;
                let payouts;
                
                commissions = this.storePartners.calcTotalCommissions(this.state.partner, this.state.sales);
                commissions = commissions.replace(',','.');
                commissions = parseFloat(commissions);

                payouts = this.storePartners.calcTotalPayouts(this.state.payouts);
                payouts = payouts.replace(',','.');
                payouts = parseFloat(payouts);

                total = commissions - payouts;
                total = this.formatNumber(total, 2);
                
                return total;
            }

            formatNumber = (price, decimals) => {
                let priceDisplay = parseFloat(price).toFixed(decimals);
                priceDisplay = priceDisplay.toString().replace('.',',');
                
                return priceDisplay;
            }

            formatDate = (date) => {
                const dateFormatted = moment(date.date).format(this.storeUi.format_date_simple)

                return dateFormatted;
            }

            render() {
                return (  
                    <div className="content-section--report">     
                        <div className="content-section--report__section">
                            <div className="content-section--report__columns">
                                <div className="content-section--report__column">
                                    Account ID
                                </div>
                                <div className="content-section--report__column">
                                    {this.props.partner.account_id}
                                </div>
                            </div>
                            
                            <div className="content-section--report__columns">
                                <div className="content-section--report__column">
                                    Naam
                                </div>
                                <div className="content-section--report__column">
                                    {this.state.partner.firstname}  {this.state.partner.lastname}
                                </div>
                            </div>
                            <div className="content-section--report__columns">
                                <div className="content-section--report__column">
                                    Woonplaats
                                </div>
                                <div className="content-section--report__column">
                                    {this.state.partner.city}
                                </div>   
                            </div>
                            <div className="content-section--report__columns">
                                <div className="content-section--report__column">
                                    E-mail
                                </div>
                                <div className="content-section--report__column">
                                    {this.state.partner.email}
                                </div>   
                            </div>
                        </div>

                        <div className="content-section--report__section">
                            <div className="content-section--report__columns">
                                <div className="content-section--report__column">
                                    Bedrijfsnaam
                                </div>
                                <div className="content-section--report__column">
                                    {this.state.partner.company}
                                </div>
                            </div>
                            <div className="content-section--report__columns">
                                <div className="content-section--report__column">
                                    Bankrekening
                                </div>
                                <div className="content-section--report__column">
                                    {this.state.partner.account_nr}
                                </div>
                            </div>
                            <div className="content-section--report__columns">
                                <div className="content-section--report__column">
                                    Naam rekening
                                </div>
                                <div className="content-section--report__column">
                                    {this.state.partner.account_name}
                                </div>
                            </div>
                            <div className="content-section--report__columns">
                                <div className="content-section--report__column">
                                    Plaats rekening
                                </div>
                                <div className="content-section--report__column">
                                    {this.state.partner.account_city}
                                </div>
                            </div>
                            <div className="content-section--report__columns">
                                <div className="content-section--report__column">
                                    Btw-nummer
                                </div>
                                <div className="content-section--report__column">
                                    {this.state.partner.vat_nr}
                                </div>
                            </div>
                        </div>

                        <div className="content-section--report__section">
                            <div className="content-section--report__columns">
                                <div className="content-section--report__column">
                                    Partnercode
                                </div>
                                <div className="content-section--report__column">
                                    {this.state.partner && this.state.partner.partnerCode}
                                </div>
                            </div>
                            <div className="content-section--report__columns">
                                <div className="content-section--report__column">
                                    Huidige vergoeding eigen uitgaven
                                </div>
                                <div className="content-section--report__column">
                                    {this.state.partner.commission_current != null
                                    ?    this.state.partner && this.formatNumber(this.state.partner.commission_current, 0) + " %"
                                    :    this.state.partner && this.formatNumber(this.state.partner.commission, 0) + " %"
                                    }
                                </div>
                            </div>
                            <div className="content-section--report__columns">
                                <div className="content-section--report__column">
                                    Huidige vergoeding uitgaven derden
                                </div>
                                <div className="content-section--report__column">
                                    {this.state.partner.commission_current_3party != null
                                    ?    this.state.partner && this.formatNumber(this.state.partner.commission_current_3party, 0) + " %"
                                    :    this.state.partner && this.formatNumber(this.state.partner.commission_3party, 0) + " %"
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="content-section--report__section">
                            <div className="content-section--report__columns">
                                <div className="content-section--report__column">
                                    Verrekend t/m
                                </div>
                                <div className="content-section--report__column">
                                    {this.state.payouts && this.state.payouts[0] && this.formatDate(this.state.payouts[0].settled_date)}
                                </div>
                            </div>
                            <div className="content-section--report__columns">
                                <div className="content-section--report__column">
                                    Totale Omzet ({this.state.partner.vat_nr ? "incl btw" : "excl btw"})
                                </div>
                                <div className="content-section--report__column">
                                    {this.state.sales && this.calcTotalSales()}
                                </div>
                            </div>
                            <div className="content-section--report__columns">
                                <div className="content-section--report__column">
                                    Commissies
                                </div>
                                <div className="content-section--report__column">
                                    {this.state.sales && this.calcTotalCommissions()}
                                </div>
                            </div>
                            <div className="content-section--report__columns">
                                <div className="content-section--report__column">
                                    Uitbetaald
                                </div>
                                <div className="content-section--report__column">
                                    {this.state.payouts && this.calcTotalPayouts()}
                                </div>
                            </div>
                            <div className="content-section--report__columns --open">
                                <div className="content-section--report__column">
                                    Openstaande commissies
                                </div>
                                <div className="content-section--report__column">
                                    {this.state.sales && this.calcTotalCommissionsOpen()}
                                </div>
                            </div>
                        </div>
                        
                        <div className="content-section--report__columns">
                            <div className="content-section--report__column">
                                <h2>Uitbetaling</h2>
                            </div>
                        </div>
                        
                        <ReportOverviewPartnerPayout 
                            partner = {this.state.partner}
                            partnerId = {this.props.partner.account_id}
                            sales = {this.state.sales}
                            payouts = {this.state.payouts}
                            settleDateLast = {this.state.payouts && this.state.payouts[0] && this.state.payouts[0].settled_date}
                        />
                    </div>
                )
            }
        }
    )
)




export default withTranslation()(withRouter(ReportOverviewPartner));
